import { Button, IconButton, makeStyles, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Close, Email, ContactSupport as Help } from '@material-ui/icons'
import { Fragment, SyntheticEvent, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    primaryButton: {
        color: '#023e8a',
        borderColor: '#023e8a'
    },
    emailButton: {
        marginRight: '5px',
        color: '#00b4d8'
    }
})

const ContactSupport = () => {
    const classes = useStyles()
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const { onboardingTeamEmail } = useAppSelector(state => state.client)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (
        event: SyntheticEvent | MouseEvent,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <Fragment>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${onboardingTeamEmail}`}
            >
                <IconButton
                    size="small"
                    color="primary"
                    onClick={handleClose}
                    className={classes.emailButton}
                >
                    <Email />
                </IconButton>
            </a>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <Close fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<Help />}
                onClick={handleClick}
                className={classes.primaryButton}
            >
                {t('helpButton')}
            </Button>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
            >
                <SnackbarContent
                    message={
                        <>
                            <Typography gutterBottom>{t('help')}</Typography>
                            {/*<Typography>{onboardingTeamEmail}</Typography>*/}
                        </>
                    }
                    action={action}
                />
            </Snackbar>
        </div>
    );
}

export default ContactSupport