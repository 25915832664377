import { useNavigate } from 'react-router-dom';
import MckinseyLogo from "../assets/mcklogo/mckinsey-logo-image.png"
import {
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
} from "@material-ui/core"
import { McK_DEEP_BLUE } from '../styles/colors';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 20%',
        backgroundColor: McK_DEEP_BLUE,
    },
    toolBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    logoButton: {
        width: '10rem',
    },
    title: {
        flexGrow: 1,
    },
    button: {
        borderRadius: '3px',
        border: '1px solid white',
        color: 'white',
        backgroundColor: McK_DEEP_BLUE,        
        width: '60px',
        fontSize: '12px',
        padding: '3px',
    },
    select: {
        color: 'white',
        backgroundColor: McK_DEEP_BLUE,        
        //width: '65px',
        fontSize: '11px',
        padding: '3px',
        outline: 'none',
    }
}));

const Navbar = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {i18n} = useTranslation();
    let lng = i18n.language;

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar className={classes.toolBar}>
                <IconButton
                    edge="start"
                    className={classes.logoButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => { navigate('/') }}
                >
                    <img alt="mckinsey logo" src={MckinseyLogo} style={{ width: '100%', height: ' 100%' }} />
                </IconButton>
                
                {/* <select
                    id = 'lngDropdown'
                    defaultValue={lng}
                    onChange={(event) => {
                        changeLanguage(event.target.value)
                    }}
                    className={classes.select}                  
                    >
                    <option value="en">English</option>
                    <option value="fr">French</option>
                </select> */}
                
            </Toolbar>
        </AppBar>
    )
}

export default Navbar