import { makeStyles, Divider } from "@material-ui/core"
import { AssetLabelHint, AssetValidationForm } from ".."
import { McKSansMedium } from "../../styles/fonts"
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    heading: {
        fontFamily: McKSansMedium,
        fontSize: '40px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '36px',
        }
    }
}))

const AssetValidation = () => {
    const classes = useStyles()
    const {t} = useTranslation('common');
    return (
        <div className={classes.root}>
            <h3 className={classes.heading}>{t('get_its_label.title')}</h3>
            <Divider style={{ margin: '10px 0', visibility: 'hidden' }} />
            <AssetValidationForm />
        </div>
    )
}

export default AssetValidation