import {
    makeStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    TextField
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import AssetTagImg from "../../assets/images/asset-tag.png"
import { McK_ELECTRIC_BLUE } from "../../styles/colors"
import { McKSansLight } from "../../styles/fonts"
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
//import "../styles/captcha.css";

const useStyles = makeStyles({
    heading: {
        fontFamily: McKSansLight,
        fontSize: '18px',
        color: McK_ELECTRIC_BLUE,
    },
    assetTagHint: {
        width: '290px',
        marginTop: '30px',
        marginBottom: '30px',
        '& img': {
            width: '250px',
            height: '150px',
        }
    },
    captchaText: {
        fontSize: '24px', // Increase the font size as needed
        fontWeight: 'bold',
    }
})

const AssetLabelHint = () => {

    const randomString = Math.random().toString(36).slice(8);
    const [captcha, setCaptcha] = useState(randomString);
    const [text, setText] = useState("");
    const [valid, setValid] = useState(false);
    const [success, setSuccess] = useState(false);
  
    const refreshString = () => {
      setText("");
      setCaptcha(Math.random().toString(36).slice(8));
    };

    interface MatchCaptchaEvent extends React.FormEvent<HTMLFormElement> {}

    const matchCaptcha = (event: MatchCaptchaEvent): void => {
        event.preventDefault();
        if (text === captcha) {
            setValid(false);
            setSuccess(true);
        } else {
            setValid(true);
            setSuccess(false);
        }
    };

    const {t} = useTranslation('common');

    const classes = useStyles()

    return(
        <div className="card">
            <CardContent>
                <CardActions>
                    <div className={classes.captchaText}>{captcha}</div>
                    <Button
                        endIcon={<RefreshIcon />}
                        onClick={() => refreshString()}
                    ></Button>
                </CardActions>
                <Divider style={{ margin: '10px 0', visibility: 'hidden' }} />
                <form onSubmit={matchCaptcha}>
                    <TextField
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        label="Enter Captcha"
                        variant="filled"
                        fullWidth
                    />

                    <Divider style={{ margin: '20px 0', visibility: 'hidden' }} />

                    
                    <Button type="submit" variant="contained" color="primary" style={{ display: 'block', margin: '20px auto' }}>
                        Submit
                    </Button>
                    <Divider style={{ margin: '20px 0', visibility: 'hidden' }} />
                </form>
            </CardContent>
        </div>
    )
}

export default AssetLabelHint
