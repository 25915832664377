import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from "axios"
import { API } from "../../config"
import { trackException, trackStackTrace } from '../../utils/logger'

interface IClient {
    userID: string
    verifyDetails: {
        verifyID: string
        isVerified: boolean
    },
    password: string
    isLoading: boolean
    errorMessage: null | string
    onboardingTeamEmail: string
}

// ClientVerifyAPIResponse defines verify link API call response body
type ClientVerifyAPIResponse = {
    userID: string
    onboardingTeamEmail: string
}

// VerifyClientSuccessPayload defines payload parameters
type VerifyClientSuccessPayload = {
    verifyID: string
    isVerified: boolean
    userID: string
    onboardingTeamEmail: string
}

const initialState: IClient = {
    userID: '',
    verifyDetails: {
        verifyID: '',
        isVerified: false,
    },
    password: '',
    isLoading: false,
    errorMessage: null,
    onboardingTeamEmail: ''
}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        verifyClientLoading(state) {
            state.isLoading = true
        },
        verifyClientSuccess(state, action: PayloadAction<VerifyClientSuccessPayload>) {
            state.isLoading = false
            state.verifyDetails.verifyID = action.payload.verifyID
            state.verifyDetails.isVerified = action.payload.isVerified
            state.userID = action.payload.userID
            state.onboardingTeamEmail = action.payload.onboardingTeamEmail
        },
        verifyClientsError(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.errorMessage = action.payload
        },
        validateAssetLabelLoading(state) {
            state.isLoading = true
        },
        validateAssetLabelSuccess(state, action: PayloadAction<{ password: string }>) {
            state.isLoading = false
            state.password = action.payload.password
        },
        validateAssetLabelError(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.errorMessage = action.payload
        },
        clearErrorMessage(state) {
            state.errorMessage = null
        },
    },
})

const { reducer, actions } = clientSlice
// export actions
export const {
    verifyClientLoading,
    verifyClientSuccess,
    verifyClientsError,
    validateAssetLabelLoading,
    validateAssetLabelSuccess,
    validateAssetLabelError,
    clearErrorMessage,
} = actions

// verify user by unique id
export const verifyAPICall = (verifyID: string) => async (dispatch: any) => {
    dispatch(verifyClientLoading())
    try {
        const resp = await axios.get(`${API.CLIENT_URL}/clients/verify/${verifyID}`)
        const apiResp: ClientVerifyAPIResponse = {
            userID: resp.data.data['id'],
            onboardingTeamEmail: resp.data.data['onboardingTeamEmail'],
        }
        trackStackTrace(`user's magic link is verified`, { apiResp })
        dispatch(verifyClientSuccess({ verifyID, isVerified: true, ...apiResp }))
    } catch (err: any) {
        const errorMessage: string = err.response?.data.message || err.message || "Unexpected error"
        trackException(new Error(errorMessage))
        dispatch(verifyClientsError(`${errorMessage[0].toUpperCase() + errorMessage.slice(1)}`))
    }
}

// validate user's asset tag
export const validateAssetLabelAPICall = (verifyId: string, assetTag: string, userID: string) => async (dispatch: any) => {
    // API call to verify serial number
    dispatch(validateAssetLabelLoading())
    const body = { assetTag: assetTag.toUpperCase(), verifyId }
    try {
        const resp = await axios.post(`${API.CLIENT_URL}/clients/${userID}/verify/asset`, body)
        trackStackTrace(`validate user's asset tabel finished`, { userID })
        dispatch(validateAssetLabelSuccess(resp.data))
    } catch (err: any) {
        let errorMessage: string = err.response?.data.message || err.message || "Unexpected error"
        trackException(new Error(errorMessage))
        // if error message is that number of allowed attempts exceeded then modify error message
        if (errorMessage.includes("validate attempts exceeded allowed maximum")) {
            errorMessage = `${errorMessage}. Contact an onboarding administrator to resend you an email with a link.`
        }
        dispatch(validateAssetLabelError(`${errorMessage[0].toUpperCase() + errorMessage.slice(1)}`))
    }
}

// export reducer
export default reducer