import clsx from 'clsx'
import { useState, ChangeEvent, MouseEvent, FormEvent, useEffect } from "react"
import {
    Button,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    makeStyles,
    CircularProgress,
    CardActions,
    Divider,
    FormHelperText,
} from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { ActionAlert } from "../"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { clearErrorMessage, validateAssetLabelAPICall } from "../../redux/reducers/client"
import { McK_ELECTRIC_BLUE, STATUS_BAD } from '../../styles/colors'
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";

interface State {
    assetTag: string
    showAssetTag: boolean
}

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    primaryButton: {
        marginTop: '20px',
        width: '150px',
        alignSelf: 'center',
    },
    submitButton: {
        textTransform: 'capitalize',
        backgroundColor: McK_ELECTRIC_BLUE,
    },
    textField: {
        width: '290px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    captchaText: {
        fontSize: '24px', // Increase the font size as needed
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center', /* Align text and button vertically */
        justifyContent: 'flex-start', /* Align content to the left */
        width: '100%',
        gap: '8px', /* Space between text and the button */
        
    },
refreshButton: {
    color: McK_ELECTRIC_BLUE,
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    marginLeft: 'auto', /* Push the button to the end */
},
container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
},
captchaContainer: {
    display: 'flex', /* Horizontal layout for captcha text and button */
    alignItems: 'center',
    alignSelf: 'center', /* Center the captcha text and button */
  },

  errorText: {
    color: STATUS_BAD,
    fontSize: '0.875rem', /* Slightly smaller font for helper text */
    marginTop: '4px',
  }

})

const AssetValidationForm = () => {
    const classes = useStyles()
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch()
    const { verifyDetails, userID, isLoading, errorMessage } = useAppSelector(state => state.client)

    const [values, setValues] = useState<State>({
        assetTag: '',
        showAssetTag: true,
    })

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // API call to verify serial number
        if (values.assetTag === captcha) {
            setErroR("");
            dispatch(validateAssetLabelAPICall(verifyDetails.verifyID, "ITS00000000000", userID)) //values.assetTag hard coding the value for now, once we know what type of mfa will be used we can make use of it
        }
        else {
            setErroR("The code does not match");
        
        }
        
    }


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, assetTag: event.target.value })
    }

    const refreshString = () => {  
        values.assetTag = '';
        let checkString = '';
        setErroR("");
      while (checkString.length < 7) {
        checkString += Math.random().toString(36).substring(2);
      }
        setCaptcha(checkString.substring(0, 7));
      };

    const [erroR, setErroR] = useState("");
    const [captcha, setCaptcha] = useState("");
  
    const effect = useEffect;

    effect(() => {
        refreshString();
      }, []);

return (
        <div className={classes.container}>

            <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            
            <CardActions className={classes.captchaContainer}>
            <div className={classes.captchaText}>{captcha}</div>
                   
                        <Button
                        className={classes.refreshButton}
                        endIcon={<RefreshIcon />}
                        onClick={() => refreshString()}>

                    </Button>
                </CardActions>
                <FormControl className={classes.textField}>
               
                    <InputLabel htmlFor="standard-adornment-password">{t('get_its_label.field')}</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={values.showAssetTag ? 'text' : 'password'}
                        value={values.assetTag}
                        onChange={handleChange}
                        autoComplete="off"
                         
                    />
                </FormControl>

                {erroR && (
                    <FormHelperText className={classes.errorText}>
                         {erroR}
                        </FormHelperText>
                              )}


                {
                    isLoading ?
                        <CircularProgress className={classes.primaryButton} />
                        :
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={clsx(classes.primaryButton, classes.submitButton)}
                        >
                            {t('get_its_label.submit')}
                        </Button>
                }
                {
                    // show action alert only if error is not null
                    errorMessage !== null &&
                    <ActionAlert
                        isError={errorMessage !== null} // value is true if error is NOT null
                        message={errorMessage || 'Unexpected error'} // error message
                        isOpen={errorMessage !== null} // value is true if error is NOT null
                        onClose={() => {
                            dispatch(clearErrorMessage()) // on close button click set errorMessage to null
                        }}
                    />
                }
            </form>
        </div>
    )
}

export default AssetValidationForm

