import { useEffect } from "react"
import { Navigate, useMatch} from "react-router-dom"
import { CircularProgress, makeStyles } from "@material-ui/core"
import { AssetValidation, ContactSupport } from "../components"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { verifyAPICall } from "../redux/reducers/client"
import { appInsights } from "../config"
import LanguageSelector from "../components/LanguageSelector"

interface MatchParams {
    id: string;
}


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'center',
    },
    contactSupport: {
        alignSelf: 'flex-end',
        marginTop: '50px',
        marginRight: '5%',
        marginBottom: '4%',
    }
}))
const Verify = () => {
    const dispatch = useAppDispatch()
    const { verifyDetails, errorMessage, password } = useAppSelector(state => state.client)
    const userMatch = useMatch('/verify/:id')?.params as MatchParams
    const classes = useStyles()
    appInsights.trackPageView({ name: 'VerifyAssetLabelPage' })
    // Parse query parameters
    useEffect(() => {
        dispatch(verifyAPICall(userMatch.id))
    }, [])

    const renderContent = (): JSX.Element => {
        if (errorMessage?.includes("validate attempts exceeded allowed maximum")) {
            return <Navigate to="/error" />
        }
        // if password is successfully retrieved show it to user
        if (verifyDetails.isVerified && password !== "" && password.length > 2) {
            return <Navigate to="/show/password" />
        }
        
        //Not needed for the MVP of CFS CTE 
     //   if all good show serial number validation component        
        if (verifyDetails.isVerified) {
            return (
                <>
                    <AssetValidation />
                    {/* <div className={classes.contactSupport}>
                        <ContactSupport />
                    </div> */}
                </>
            )
        }
        // if (verifyDetails.isVerified) {
        //     return (
        //             <div className={classes.root}> 
        //                  <Captcha />
        //              </div>
                
        //     )
        // }

        // if API call failed redirect to Error page
        if (errorMessage !== null) {
            return <Navigate to="/error" />
        }

        // if API call is in progress show progress bar
        return <CircularProgress style={{ marginTop: '15%' }} />
    }

    return (
        <div className={classes.root}>
            {renderContent()}
            <div>
                <LanguageSelector />
            </div>
        </div>
        
    )
}

export default Verify